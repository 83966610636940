* {
  margin: 0;
  padding: 0;
  border: 0;
}

body {
  background-color: #021034;
}

@keyframes beep {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.video-wrapper {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: hidden;
}

.video-wrapper .video-aspect {
  height: 100%;
  width: 160vh; /* 100 * 16 / 10 */
  min-width: 100%;
  min-height: 62.5vw; /* 100 * 10 / 16 */
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  background-image: url('./img/static-bg.jpg');
  background-size: cover;
  background-position-y: 100%;
}

.video-wrapper .booster-video {
  width: 10.75%;
  position: absolute;
  top: 14.25%;
  left: 49.65%;
  transform: translateX(-50%);
}

.video-wrapper .lower-video {
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
}

.title {
  position: absolute;
  height: 28%;
  width: 26%;
  max-width: 95vw;
  top: 6%;
  left: 50%;
  transform: translateX(-50%);
}

.title .word {
  position: absolute;
}

.title .boosters {
  top: 9%;
  width: 100%;
  transform: rotate(1.5deg);
}

.title .big {
  bottom: 20%;
  left: 1.5%;
  width: 25%;
  transform: rotate(-1.5deg);
}

.title .neighborhood {
  bottom: 0;
  width: 100%;
  transform: rotate(-0.9deg);
}

.content {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: 42%;
  width: 360px;
  max-width: 82vw;
}

.store-badges {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.store-badge {
  height: 4vh;
  min-height: 40px;
  max-height: 55px;
  margin: 0 10px;
}

footer {
  position: absolute;
  bottom: 0;
  width: 100%;
}

footer ul {
  list-style: none;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

footer a {
  color: rgba(255, 255, 255, 0.8);
  font-weight: 600;
  padding: 10px 15px;
  display: block;
  font-size: 12px;
}

h2 {
  font-family: 'Josefin Sans', sans-serif;
  color: #fff;
  text-align: center;
  font-size: 1em;
  line-height: 1.8em;
  margin-bottom: 3vh;
}

@media (min-width: 460px) {
  h2 {
    font-size: 1.1em;
  }
}
